body {
  font-family: 'Noto Sans JP', 'Hiragino Sans', sans-serif;
}

html {
font-size: 10px ;
}

/* スマホ */
@media screen and (max-width:599px) {

.smartphoneArea {
  display: block;
}

.backGroundBlue {
  background-color: #e9eef6;
  padding: 1rem 0 0;
}

.pcArea {
  display: none;
}

Link {
  text-decoration: none;
}

.titleBlock {
  margin: 20px 0px;
}

.title {
  white-space:pre-line;
  font-size: 20px;
  font-weight: bold;
  color: #0D63BE;
  padding: 0 10px;
  border-left: solid 5px #0D63BE;
  line-height: 1;
  margin: 0 0 20px;
}

.titleLimitedOffer {
  white-space:pre-line;
  font-size: 20px;
  font-weight: bold;
  color: #fd3e04;
  padding: 0 10px;
  border-left: solid 5px #fd3e04;
  line-height: 1.2;
  margin: 0 0 20px;
}

.sentenceBoldBlack {
  line-height: 1.6;
  padding: 0 10px 0px;
  font-size: 1.4rem;
  list-style: none;
}

.sentenceBoldBlack span {
  color: #000;
  font-weight: bold;
}

.sentenceBoldRed {
  line-height: 1.6;
  padding: 0 10px 0px;
  font-size: 1.4rem;
  list-style: none;
}

.sentenceBoldRed span {
  color: red;
  font-weight: bold;
}

.icon{
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.topPageContainer > .mainSection {
  border-top: 3px solid #1156bd;
}

/* Header */

.headerSection {
  max-width: 100%;
  background-color: white;
  padding: 5px 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
}

.CompanyLogo {
  margin: 10px 10px;
  width: 165px;
}

.toTeacher {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 10px;
}

.toTeacher img {
  width: 15px;
}

.toTeacher > p {
  margin-left: 3px;
  font-size: 12px;
  color: #1179EF;
  font-weight: bold;
}

.Eyecatch {
  max-width: 100%;
  margin-bottom: 15px;
}

/* CatchCopy */

.catchCopy {
  margin-top:10px ;
  background-color: white;
  padding:0 15px;
}

/* Button */

:root {
  --teacherRegistration: #1179EF;
  --studentRegistration: #FF6400;
}

.btn {
  font-family: "Noto Sans JP", sans-serif;
  border-radius: 2px;
}

.btn--teacherRegistration {
  background-color: var(--teacherRegistration);
  color: white;
  font-weight: bold;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  box-shadow: 0px 0px 15px -5px #777777;
}

.buttonTeacherRegistration{
  text-align: center;
  position: relative;
  margin: 0 0 20px;
}

.btn--studentRegistration {
  background-color: var(--studentRegistration);
  color: white;
  font-weight: bold;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  box-shadow: 0px 0px 15px -5px #777777;
}

.buttonStudentRegistration {
  text-align: center;
  position: relative;
  margin: 0 auto;
}

/* About */

.about {
  background: #fff;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-content: space-between;
  border-bottom: 1px solid #E1E8F6;
}

.aboutContent {
  list-style: none;
}

.aboutList {
  width: 100%;
  padding: 10px 40px 10px 0px;
  display: flex;
  text-decoration: none;
  position: relative;
  color: #495361;
  border-top: 1px solid #E1E8F6;
}

.listSumbnail {
  width: 100px;
  height: auto;
  border: solid 1px #CCCCCC;
}

.listText {
  flex: 1;
  margin-left: 10px;
  line-height: 1.2;
}

.listText > #title {
  height: 28px;
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #006FF3;
  white-space: pre-line;
}

.listText > #description {
  color: #495361;
  font-size: 12px;
  width: 205px;
  letter-spacing: 0.01em;
}

.listIcon{
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

/* Guide */

.guideContainer {
  margin-top: 1rem;
}

.guideListItem {
  padding: 1.5rem;
  margin: 25px 0;
  background-color: #e9eef6;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  list-style-type: none;
}

.guideListTitle {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  color: #3a3f4a;
}

.guideListTitle > #number {
  display: block;
  padding-bottom: 0.5rem;
  font-size: 200%;
  color: #073898;
}

.guideListTitle > #title {
  color: #FF651C;
  margin: 0 0 16px;
}

.guideContentLeft > #description {
  margin: 10px 0;
  font-size: 1.4rem;
}

.guideImg {
  max-width: 100%;
}

/* NewRegistars */

.newRegistarsContainer {
  margin-bottom: 20px;
}

.boxTitle {
  background-color: #FDEDA0;
}

.boxTitle p {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #495361;
  padding: 8px;
}

.boxContent {
  background-color: #FEFAE6;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.number {
  display: flex;
  margin: 10px 10px;
  align-items: center;
}

.number img {
  width: 48px;
  height: auto;
}

.numbers {
  margin: 10px 10px;
  font-size: 16px;
  font-weight: bold;
  color: #495361;
  line-height: 1.3;
}

/* Footer */

.footerContainer {
  display: block;
  background-color: #fff;
  border-top: 1px solid #E1E8F6;
}

.footerList {
  color: #1179EF;
  padding: 15px 20px;
  list-style: none;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #E1E8F6;
  width: 100%;
}

.footerLogo {
  margin: 10px 10px 0px;
}

/* Price */

.priceContainer {
  margin: 2rem 0 2rem;
  padding: 0 15px;
}

.titleBox {
  background-color: #0D63BE;
  display: flex;
  justify-content: center;
}

.titleBoxContent {
  font-size: 15px;
  color: #fff;
  margin: 13px 30px;
}

.priceTable {
  max-width: 800px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  border: 1px solid #073898;
  border-bottom: 0;
  margin: 10px 0px;
}
.tabelTitleUs {
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  color: #073898;
  background-color: #FDEDA0;
  border-bottom: 1px solid #073898;
}

.tabelTitle {
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  color: #073898;
  background-color: #e9eef6;
  border-bottom: 1px solid #073898;
}

.tableData {
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #073898;
  font-size: 14px;
}

.tableData > dt {
  width: 30%;
  padding: 1rem;
  color: #073898;
  margin: auto;
}

.tableData > dd {
  width: 70%;
  padding: 1rem;
  border-left: 1px solid #073898;
  color: #3a3f4a;
}

.tableData > dd > .small {
  font-size: 10px;
} 

.tableData > dd > .red {
  color: #ff0000;
} 

.priceNotion {
  font-size: 12px;
  list-style: none;
}

/* Terms */

.termsContainer {
  margin: 40px 0px;
  padding: 0 15px;
}

/* RegistrationStudent */
.registrationStudentContainer {
  margin: 1.5rem 0;
  padding: 0 15px;
}

.registrationStudentForm {
  width: 100%;
  flex-wrap: wrap;
  height: 50rem;
}

  /* Teacher */

  .MiniContainer {
    padding: 0 15px;
  }

/* Questions */

.questionsContainer {
  padding: 25px 15px 10px;
  list-style: none;
  background-color: #e9eef6;
}

.questionsContent {
  padding: 2rem;
  margin: 0 0 2.5rem;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  line-height: 1.5em;
}

.questionSentence {
  display: flex;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.questionIcon {
  font-size: 18px;
  font-weight: bold;
  color: #0D63BE;
  margin-right: 10px;
}

.questionTheme {
  font-size: 16px;
  font-weight: bold;
  color: #0D63BE;
}

.answerIcon {
  font-size: 18px;
  font-weight: bold;
  color:#D2D2D2;
  margin-right: 10px;
}

.answerTheme {
  font-size: 1.4rem;
  color: #495361;
  letter-spacing: 0.1rem;
}

.referenceSentence {
  font-size: 1.4rem;
  color: #0D63BE;
  margin-left: 2rem;
}

/* Voice */
.voiceContainer {
  padding: 2.5rem 1.5rem 2rem;
  list-style: none;
  background-color: #e9eef6;
  margin: 2rem 0;
}

.voiceContainer > .heading {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2rem;
}

.voiceContent {
  padding: 1.8rem;
  margin: 0 0 25px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  letter-spacing: 0em;
  line-height: 1.5em;
  list-style: none;
}

.voiceHeading {
  font-size: 16px;
  font-weight: bold;
  color: #3a3f4a;
}

.voiceHeading > section {
  content: "";
  margin-top: 0.6rem;
  display: block;
  width: 48px;
  height: 2px;
  border-radius: 1px;
  background: #777;
}

.voiceText {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 1;
  margin-top: 20px;
  line-height: 1.5;
}

.voiceFrom {
  font-size: 1.2rem;
  text-align: right;
  line-height: 1.2;
  color: #073898;
  font-weight: bold;
}

.teacherGuideContainer {
  background-color: #fff;
  padding: 0 15px;
}

/* サイトマップ */

.sitemapContainer {
  margin: 0 2rem;
}

.sitemapSection {
  margin: 3rem 0;
}

.sitemapSection > h2 {
  color: #495361;
  border-bottom: solid 1px #8fb0e0;
  font-size: 1.8rem;
}

.sectionContent {
  list-style: none;
  margin-top: 1rem;
  font-size: 1.6rem;
  color: #495361;
}

.sectionContent li {
  width: 30rem;
}

 /* Reissue */

 .reissueContainer {
  margin: 40px 0px;
  padding: 0 15px;
}

/* 利用規約 */
.termsContent {
  font-size: 1.4rem;
}

/* Company */

.companyContainer {
  padding: 0 15px;
}

.companySection {
  border-bottom: solid 1px #E1E8F6;
  display: flex;
}

.companyHeading {
  width: 10rem;
  padding: 1.6rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.companyContent {
  padding: 1.6rem 1rem;
  font-size: 1rem;
}

/* Articles  otherArticles */
.companyContainer {
  width: 100%;
  margin: 0 auto;
}

.articleContainer {
  width: 100%;
  margin: auto 0;
  border-top: 1px solid #0D63BE;
}

.articleContainer > .articleContent {
  background-color: #ffffff;
  display: block;
  width: 100%;
  margin: 0 0 2rem;
}

.articleContainer > .articleContent > .articleDate {
  font-size: 1.4rem;
  color: #BFBFBF;
  margin: 2rem 3rem 0;
}

.articleContainer > .articleContent > .articleTitle {
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 3rem 1rem;
}

.articleContainer > .articleContent > .articleEyecatch {
  margin: 2rem 3rem 1rem;
}

.articleContainer > .articleContent > .toc {
  margin: 2rem 3rem 1rem;
}

.articleContainer > .articleContent > .toc > ul {
  list-style: none;
}

.articleContainer > .articleContent > .articleBody

.articleContainer > .articleContent > .articleBody > .articleLead {
  margin: 2rem 3rem 0 0;
  background-color: #f1f1f1;
  border-left: 1px solid #495361;
}

.articleContainer > .articleContent > .articleBody > .articleLead > p {
  margin: 2rem 1rem 3rem;
  padding: 1.6rem;
  font-size: 2rem;
  font-weight: 600;
}

.articleContainer > .articleContent > .articleBody > .articleText {
  margin: 3rem;
  font-size: 1.6rem;
}

.articleContainer > .rightContents {
  width: 85%;
  margin: 0 auto;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi {
  margin: 3rem 0;
  padding:2rem;
  border: 1px solid #D9D9D9;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi > .heading {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi > .text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence {
  margin-bottom: 3rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > h4 {
  font-size: 1.6rem;
  border-bottom: 1px solid #D9D9D9;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > .black {
  font-weight: 700;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > .red {
  font-weight: 700;
  color: #ff0000;
}

.otherArticles {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
}

.otherArticles > .heading {
  background-color: #4096F1;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.otherArticles > .heading > p {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.8rem;
  padding: 1.6rem;
  text-align: center;
}

.otherArticles > .list {
  background-color: #ffffff;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.otherArticles > .list > a {
  text-decoration: none;
  color: #000;
}

.otherArticles > .list > a > .items {
  display: flex;
  width: 100%;
  padding: 0.8rem;
}

.otherArticles > .list > a > .items > .image > img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}

.otherArticles > .list > a > .items > .articleTitle {
  margin: 1rem 0 1rem 1rem;
  font-size: 1.4rem;
  width: 70%;
}

/* Articles  newRegisters */

.newRegistration {
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
border-radius: 1rem;
margin-bottom: 2rem;
}

.newRegistration > .heading {
background-color: #4096F1;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
margin-top: 3rem;
}

.newRegistration > .heading > p {
color: #ffffff;
font-weight: 500;
font-size: 1.8rem;
padding: 1.6rem;
text-align: center;
}

.newRegistration > .newsList {
list-style: none;
background-color: #ffffff;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;
overflow-y: scroll;
height: 18rem;
}

.newRegistration > .newsList > .yellow {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
background-color: #FDEDA0;
}

.newRegistration > .newsList > .gray {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
background-color:#F2F2F2;
}

.newRegistration > .newsList > .white {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;

}

.topPageContainer > .mainSection > .rightContents {
width: 90%;
margin: 2rem auto;
}

.topPageContainer > .mainSection > .mainContents {
padding: 0 1.5rem;
}

/* RecruitPage */

.recruitePageContainer > .mainSection {
border-top: 3px solid #1156bd;
}

.recruitePageContainer > .mainSection > .rightContents {
width: 90%;
margin: 2rem auto;
}

.recruitePageContainer > .mainSection > .mainContents {
padding: 0 1.5rem;
}

.mainSection > .mainContents > .recruitInfo {
border: 1px solid #073898;
border-bottom: 0;
margin: 3rem auto;
max-width: 100rem;
text-align: center;
}

.mainSection > .mainContents > .recruitInfo > .tableTitle {
border-bottom: 1px solid #073898;
background-color: #073898;
color: #ffffff;
font-size: 2rem;
margin: 0;
padding: 1rem;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem {
border-bottom: 1px solid #073898;
display: flex;
flex-wrap: wrap;
font-size: 1.6rem;
width: 100%;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dt {
color: #073898;
margin: auto;
padding: 1rem;
width: 30%;
font-weight: bold;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dd {
border-left: 1px solid #073898;
color: #3a3f4a;
padding: 2rem;
width: 70%;
}

}


/* PC */

@media screen and (min-width:600px) {

.backGroundBlue {
  background-color: #e9eef6;
  padding: 3.2rem 0;
}

.smartphoneArea {
  display: none;
}

.pcArea {
  display: block;
}

Link {
  text-decoration: none;
}
  
.limitedOffer {
  margin: 0 0 2rem;
}

.titleLimitedOffer {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 700;
  color: #fd3e04;
  padding: 2rem 1rem;
  line-height: 1.4;
  letter-spacing: .1em;
  margin: 0 0 20px;
  position: relative;
}

.titleLimitedOffer::before {
  position: absolute;
  bottom: 0;
  left: calc(50% - 30px);
  width: 7rem;
  height: 0.5rem;
  content: "";
  border-radius: 3px;
  background-color: #f46036;
}

.title {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 700;
  color: #0D63BE;
  padding: 2rem 1rem;
  line-height: 1.4;
  letter-spacing: .1em;
  margin: 0 0 20px;
  position: relative;
}

.title::before {
  position: absolute;
  bottom: 0;
  left: calc(50% - 30px);
  width: 7rem;
  height: 0.5rem;
  content: "";
  border-radius: 3px;
  background-color: #f46036;
}

.sentenceBoldBlack {
  line-height: 1.5;
  padding: 0 1rem 0;
  text-align: center;
  font-size: 1.6rem;
  color: #3a3f4a;
  margin: 3.2rem 0 1.2rem;
  list-style: none;
}

.sentenceBoldRed {
  padding: 0 1rem 0;
  text-align: center;
  font-size: 1.6rem;
  color: #3a3f4a;
  margin: 3.2rem 0;
  list-style: none;
  line-height: 1.6;
}

.sentenceBoldBlack span {
  color: #000;
  font-weight: bold;
}

.sentenceBoldRed span {
  color: red;
  font-weight: bold;
}

.icon{
  display: none;
}

.MiniContainer {
  background-color: #fff;
}

/* Header */

.headerSpace {
display: flex;
flex-direction: column;
border-bottom: 1px solid #eee;
z-index: 7;
height: 10.5rem;
}

.header {
width: 100%;
position: fixed;
z-index: 2;
box-shadow: 2px 4px 17px rgb(0 0 0 / 9%);
background-color: white;
padding: 5px 10px;
}

.headerSection {
width: 100rem;
margin: 0 auto;
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
align-items: center;
}

/* Nav */

.headerNav {
background-color: #f1f1f1;
height: 6rem;
}

.navUnit {
position: relative;
width: 100%;
height: 50px;
max-width: 1000px;
margin: 0 auto;
}

.navUnit > li {
float: left;
width: 20%;
height: 6rem;
line-height: 6rem;
background: #f1f1f1;
font-size: 1.5rem;
border-left: 1px solid #d5d5d5;
}

.navUnit > li:last-child {
border-right: 1px solid #d5d5d5;
}

.navUnit > li > p {
display: block;
color: #000000;
font-weight: 600;
}

.navUnit > li p:hover {
color: #999;
}

.navUnit,.dropdownLists > li {
list-style: none;
text-align: center;
}

.headerNav > li:hover {
background: #072A24;
-webkit-transition: all .5s;
transition: all .5s;
}

.dropdownLists > li {
border-top: 0.1px solid #ffffff;
}

.dropdownLists > a {
text-decoration: none;
color: #000;
list-style: none;
}

.dropdownLists li p:hover {
background: #0D63BE;
color: #fff;
cursor: pointer;
}

.dropdownLists {
visibility: hidden;
opacity: 0;
z-index: 1;
}

.navList {
position: relative;
}

.dropdownLists {
position: absolute;
top: 40px;
width: 100%;
background: #D9D9D9;
-webkit-transition: all .2s ease;
transition: all .2s ease;
}

.navList:hover .dropdownLists {
top: 6rem;
visibility: visible;
opacity: 1;
}

/* Header */
.CompanyLogo {
margin: 2rem 2rem 2rem 0.3rem;
width: 24rem;
}

.headerLinks {
display: flex;
margin-right: 0.3rem;
}

.toTeacher {
align-items: center;
background-color: #0D63BE;
box-shadow: #2a395c 0 0.3rem 0;
width: 24rem;
padding: 0.8rem 1.2rem;
border-radius: 0.5rem;
margin-right: 20px;
}

.toStudentRegistration {
align-items: center;
background-color: #f46036;
box-shadow: #c43100 0 0.3rem 0;
width: 24rem;
padding: 0.8rem 1.2rem;
border-radius: 0.5rem;
}

.toTeacher img {
display: none;
}

.toTeacher,.toStudentRegistration > p {
text-align: center;
font-size: 1.8rem;
color: #fff;
font-weight: bold;
}

.Eyecatch {
max-width: 100%;
margin-bottom: 15px;
}

.EyecatchBig {
max-width: 100%;
margin: 0 auto 1.5rem;
}

/* CatchCopy */

.catchCopyContainer {
background-color: #fff;
}

.catchCopy {
margin-top:10px ;
padding:0 15px;
}

/* Button */

:root {
--teacherRegistration: #1179EF;
--studentRegistration: #FF6400;
}

.btn {
font-family: "Noto Sans JP", sans-serif;
border-radius: 2px;
}

.btn--teacherRegistration {
background-color: var(--teacherRegistration);
color: white;
font-weight: bold;
border: none;
width: 50%;
height: 8rem;
font-size: 2.4rem;
padding: 10px 20px;
box-shadow: 0px 0px 15px -5px #777777;
}

.buttonTeacherRegistration{
text-align: center;
position: relative;
margin: 0 0 2rem;
}

.btn--studentRegistration {
background-color: var(--studentRegistration);
color: white;
font-weight: bold;
border: none;
width: 50%;
height: 8rem;
font-size: 2.4rem;
padding: 10px 20px;
box-shadow: 0px 0px 15px -5px #777777;
}

.buttonStudentRegistration {
text-align: center;
position: relative;
max-width: 100rem;
margin: 0 auto;
}

/* About */

.aboutContainer{
padding: 0 15px;
background: #fff;
}

.about {
flex-direction: column;
align-items: center;
flex-wrap: wrap;
border-bottom: 0px solid #E1E8F6;
padding: 2rem;
}

.aboutContent {
list-style: none;
}

.aboutList {
display: flex;
width: 100%;
background-color: #fff;
border-radius: 3px;
box-shadow: 0px 1px 10px 0px rgb(17 86 189 / 12%);
margin: 0 auto 1.5rem;
position: relative;
}

.aboutList:hover{
background: #f6f9ff;
}

.listSumbnail {
width: 13rem;
height: auto;
}

.listText {
flex: 1;
margin: 0 4rem 0 2rem;
line-height: 1.2;
padding: 2rem 1rem;
}

.listText > #title {
height: 2.8rem;
font-size: 1.6rem;
font-weight: bold;
display: flex;
-webkit-box-align: center;
align-items: center;
color: #006FF3;
white-space: pre-line;
margin-bottom: 1rem;
}

.listText > #description {
color: #495361;
font-size: 1.6rem;
letter-spacing: 0.01em;
}

.listIcon{
position: absolute;
right: 8px;
top: 50%;
transform: translateY(-50%);
}

/* Guide */

.guideContainer {
margin-top: 4rem;
padding: 0 1.5rem 1rem;
}

.guideContent {
max-width: 100rem;
margin: 0 auto;
position: relative;
}

.guideListItem {
display: flex;
align-items: center;
justify-content: space-around;
padding: 1.5rem;
background-color: #e9eef6;
box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
list-style-type: none;
margin: 3.2rem 0 2.4rem;
}

.guideContentLeft {
width: 65%;    
}

.guideListTitle {
display: flex;
font-size: 2.4rem;
font-weight: bold;
text-align: left;
line-height: 1.2;
align-items: flex-end;
}

.guideListTitle > #number {
font-size: 200%;
padding-right: 0.5rem;
display: inline-block;
vertical-align: sub;
color: #073898;
}

.guideListTitle > #title {
color: #FF651C;
margin: 0 0 0.6rem 1rem;
}

.guideContentLeft > #description {
margin: 10px 0;
font-size: 1.6rem;
}

.guideImgs {
width: 30%;
}

.guideImg {
max-width: 100%;
height: auto;
}

/* NewRegistars */

.newRegistarsContainer {
margin: 3rem auto 2rem;
box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 35%);
border-radius: 1rem;
}

.newRegistarsContainer > .heading {
background-color: #A5A5A5;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
}

.newRegistarsContainer > .heading > p {
color: #ffffff;
font-weight: 500;
font-size: 1.8rem;
padding: 1.6rem;
text-align: center;
}

.newRegistarsContainer > .boxContent {
background-color: #FEFAE6;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;
}

.newRegistarsContainer > .boxContent > .number {
display: flex;
align-items: center;
margin: 0 5rem;
}

.number img {
width: 48px;
height: auto;
}

.numbers {
margin: 10px 10px;
font-size: 16px;
font-weight: bold;
color: #495361;
line-height: 1.3;
}

/* Footer */

.footerContainer {
display: block;
background-color: #fff;
border: 1px solid #E1E8F6;
width: 100rem;
margin: 2em auto;
}

.footerList {
color: #1179EF;
padding: 15px 20px;
list-style: none;
font-size: 14px;
align-items: center;
border-bottom: 1px solid #E1E8F6;
width: 100%;
}

.footerLogo {
margin: 10px 10px 0px;
}

/* PCのみ表示するTOPページのPrice */
.pcPriceContainer {
background-color: #fff;
padding: 4rem 1.5rem 3rem;
}

.pcPriceTable {
position: relative;
}

/* Price */

.priceContainer {
margin-top: 40px;
padding: 0 1.5rem 3rem;
}

.titleBox {
background-color: #0D63BE;
display: flex;
justify-content: center;
}

.titleBoxContent {
font-size: 1.8rem;
color: #fff;
margin: 13px 30px;
}

.priceTable {
max-width: 100rem;
font-weight: bold;
text-align: center;
border: 1px solid #073898;
border-bottom: 0;
margin: 3rem auto;
}
.tabelTitleUs {
margin: 0;
padding: 1rem;
font-size: 2rem;
color: #073898;
background-color: #FDEDA0;
border-bottom: 1px solid #073898;
}

.tabelTitle {
margin: 0;
padding: 1rem;
font-size: 2rem;
color: #073898;
background-color: #e9eef6;
border-bottom: 1px solid #073898;
}

.tableData {
width: 100%;
font-size: 1.6rem;
display: flex;
flex-wrap: wrap;
border-bottom: 1px solid #073898;
}

.tableData > dt {
width: 30%;
padding: 1rem;
color: #073898;
margin: auto;
}

.tableData > dd {
width: 70%;
padding: 2rem;
border-left: 1px solid #073898;
color: #3a3f4a;
}

.tableData > dd > .small {
font-size: 10px;
} 

.tableData > dd > .red {
color: #ff0000;
} 

.priceNotion {
padding: 0;
font-size: 1.2rem;
text-align: center;
list-style: none;
}

/* Terms */

.termsContainer {
margin: 40px 0px;
padding: 0 15px;
}

/* RegistrationStudent */
.registrationStudentContainer {
margin: 2rem 0px;
padding: 0 15px;
}

.formBlock {
padding: 0 16rem;
margin: 0 auto;
}

.registrationStudentForm {
height: 120rem;
background-color: #fff;
}

/* Questions */

.questionsContainer {
padding: 25px 15px 10px;
list-style: none;
background-color: #fff;
}

.questionsContent {
width: 100rem;
padding: 2.5rem;
margin: 0 auto 4rem;
border-radius: 4px;
background-color: #fff;
box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
letter-spacing: 0em;
line-height: 1.5em;
}

.questionSentence {
display: flex;
margin-bottom: 1rem;
line-height: 1.4;
}

.questionIcon {
font-size: 18px;
font-weight: bold;
color: #0D63BE;
margin-right: 10px;
}

.questionTheme {
font-size: 16px;
font-weight: bold;
color: #0D63BE;
}

.answerIcon {
font-size: 18px;
font-weight: bold;
color:#D2D2D2;
margin-right: 10px;
}

.answerTheme {
font-size: 16px;
color: #495361;
}

.referenceSentence {
font-size: 1.6rem;
color: #0D63BE;
margin-left: 2rem;
}

/* Voice */

.voiceContainer > .heading {
text-align: center;
font-size: 2.4rem;
font-weight: 700;
color: #0D63BE;
letter-spacing: .1em;
margin: 0 0 20px;
}

.voiceContainer {
background-color: #F2F2F2;
padding: 4rem 0 2rem;
border-radius: 4px;
margin-top: 3rem;
}

.voiceList {
max-width: 100rem;
list-style: none;
margin: 0 auto;
overflow-y: scroll;
height: 120rem;
}

.voiceContent {
/* width: calc(100% / 2 - 1rem); */
width: 90%;
padding: 2rem;
margin: 0 auto 25px;
border-radius: 4px;
background-color: #fff;
box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
line-height: 1.5em;
}

.voiceHeading {
font-size: 1.6rem;
font-weight: bold;
color: #3a3f4a;
line-height: 2rem;
}

.voiceHeading > section {
content: "";
margin-top: 0.8rem;
display: block;
width: 48px;
height: 2px;
border-radius: 1px;
background: #777;
}

.voiceText {
display: flex;
margin-bottom: 10px;
font-size: 14px;
margin-top: 20px;
color: #3a3f4a;
letter-spacing: 0.1em;
line-height: 1.5;
}

.voiceFrom {
font-size: 1.2rem;
text-align: right;
line-height: 1.2;
color: hsl(220, 91%, 31%);
font-weight: bold;
}

.teacherGuideContainer {
background-color: #fff;
padding: 0 15px;
margin-top: 4rem;
}

/* サイトマップ */

.sitemapContainer {
width: 100rem;
margin: 0 auto;
}

.sitemapSection {
margin: 5rem 0;
}

.sitemapSection > h2 {
color: #495361;
border-bottom: solid 1px #8fb0e0;
font-size: 1.8rem;
}

.sectionList {
display: flex;
flex-wrap: wrap;
}

.sectionContent {
list-style: none;
margin-top: 1rem;
font-size: 1.4rem;
color: #495361;
width: 30%;
}

.sectionContent > li {
width: 30rem;
}

.termsContent {
font-size: 1.4rem;
}

/* Company */

.companyContainer {
width: 100rem;
margin: 0 auto;
}

.companySection {
border-bottom: solid 1px #E1E8F6;
display: flex;
}

.companyHeading {
width: 30%;
padding: 1.6rem;
text-align: center;
font-size: 1.4rem;
font-weight: 600;
}

.companyContent {
padding: 1.6rem 1rem;
font-size: 1.4rem;
}

/* Articles  otherArticles */

.articlePage {
background-color: #f1f1f1;
}

.articleContainer {
display: flex;
width: 100rem;
margin: 0 auto;
background-color: #f1f1f1;
justify-content: space-between;
padding-top: 3rem;
}

.articleContainer > .articleContent {
background-color: #ffffff;
display: block;
width: 70%;
margin: 0 0 2rem;
}

.articleContainer > .articleContent > .articleDate {
font-size: 1.4rem;
color: #BFBFBF;
margin: 2rem 3rem 0;
}

.articleContainer > .articleContent > .articleTitle {
font-size: 2rem;
font-weight: 600;
margin: 1rem 3rem 1rem;
}

.articleContainer > .articleContent > .articleEyecatch {
margin: 2rem 3rem 1rem;
}

.articleContainer > .articleContent > .toc {
margin: 2rem 3rem 1rem;
}

.articleContainer > .articleContent > .toc > ul {
list-style: none;
font-size: 1.6rem;
}

.articleContainer > .articleContent > .articleBody {
padding-bottom: 3rem;
}

.articleContainer > .articleContent > .articleBody > .articleLead {
margin: 2rem 3rem 0 0;
background-color: #f1f1f1;
border-left: 1px solid #495361;
}

.articleContainer > .articleContent > .articleBody > .articleLead > p {
margin: 2rem 1rem 3rem;
padding: 1.6rem;
font-size: 2rem;
font-weight: 600;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence {
margin: 3rem;
font-size: 1.6rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi {
margin: 3rem;
padding:2rem;
border: 1px solid #D9D9D9;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi > .heading {
font-size: 1.4rem;
margin-bottom: 1rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .fyi > .text {
font-size: 1.2rem;
margin-bottom: 1rem;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > h4 {
font-size: 2rem;
border-bottom: 1px solid #D9D9D9;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > .black {
font-weight: 700;
}

.articleContainer > .articleContent > .articleBody > .articleText > .mainSentence > p > .red {
font-weight: 700;
color: #ff0000;
}

.articleContainer > .rightContents {
width: 27%;
}

.articleContainer > .rightContents > .voiceContainer {
background-color: #FEFAE6;
}

.otherArticles {
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
border-radius: 1rem;
}

.otherArticles > .heading {
background-color: #F2F2F2;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
}

.otherArticles > .heading > p {
color: #000000;
font-weight: 500;
font-size: 1.4rem;
padding: 1.2rem 0 1.2rem 0.6rem;
}

.otherArticles > .list {
background-color: #F2F2F2;
width: 100%;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;
}

.otherArticles > .list > a {
text-decoration: none;
color: #000;
}

.otherArticles > .list > a > .items {
display: flex;
width: 100%;
padding: 0.8rem;
}

.otherArticles > .list > a > .items > .image > img {
width: 6rem;
height: 6rem;
object-fit: cover;
}

.otherArticles > .list > a > .items > .articleTitle {
margin: auto 1rem;
font-size: 1.2rem;
width: 70%;
}

/* Articles  newRegisters */

.newRegistration {
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
border-radius: 1rem;
margin: 0 0 3rem;
}

.newRegistration > .heading {
background-color: #4096F1;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
}

.newRegistration > .heading > p {
color: #ffffff;
font-weight: 500;
font-size: 1.6rem;
padding: 1rem;
text-align: center;
}

.newRegistration > .newsList {
list-style: none;
background-color: #ffffff;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;
overflow-y: scroll;
height: 40rem;
}

.newRegistration > .newsList > .yellow {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
background-color: #FDEDA0;
}

.newRegistration > .newsList > .gray {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
background-color:#F2F2F2;
}

.newRegistration > .newsList > .white {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
}

/* TopPage */
.mainSection {
width: 100rem;
margin: 3rem auto 0;
display: flex;
justify-content: space-between;
}

.leftContents {
width: 18%;
}

.mainContents {
width: 60%;
}

.rightContents {
width: 18%;
}

/* RecruitSideBar */
.recruitSideBarContainer {
box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 35%);
border-radius: 1rem;
margin: 0 0 3rem;
}

.recruitSideBarContainer > .heading {
width: 100%;
background-color: #0D63BE;
text-align: center;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
}

.recruitSideBarContainer > .heading > p {
color: #ffffff;
margin: auto 0;
font-weight: bold;
font-size: 1.6rem;
padding: 0.8rem 1rem;
}

.recruitSideBarContainer > .contents {
overflow-y: scroll;
height: 40rem;
}

.recruitSideBarContainer > .contents > .postSection {
list-style: none;
}

.recruitSideBarContainer > .contents > .postSection > a {
text-decoration: none;
}

.recruitSideBarContainer > .contents > .postSection > a > .posts {
border-bottom: solid 0.1rem #A5A5A5;
padding: 1.2rem;
font-size: 1.4rem;
background-color: #F2F2F2;
}

.recruitSideBarContainer > .contents > .postSection > a > .posts:hover {
background-color: #ffffff;
}

.recruitSideBarContainer > .contents > .postSection > a > .posts > .date {
color: #0D63BE;
}

.recruitSideBarContainer > .contents > .postSection > a > .posts > .date > span {
color: #FAD20A;
font-weight: bold;
}

.recruitSideBarContainer > .contents > .postSection > a > .posts > .area {
color: #0D63BE;
}

/* TeacherSideBar */
.teacherSideBarContainer {
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 35%);
  border-radius: 1rem;
  margin: 0 0 3rem;
  }
  
  .teacherSideBarContainer > .heading {
  width: 100%;
  background-color: #0D63BE;
  text-align: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  }
  
  .teacherSideBarContainer > .heading > p {
  color: #ffffff;
  margin: auto 0;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 0.8rem 1rem;
  }
  
  .teacherSideBarContainer > .contents {
  overflow-y: scroll;
  height: 120rem;
  }
  
  .teacherSideBarContainer > .contents > .postSection {
  list-style: none;
  }
  
  .teacherSideBarContainer > .contents > .postSection > a {
  text-decoration: none;
  }
  
  .teacherSideBarContainer > .contents > .postSection > a > .posts {
  border-bottom: solid 0.1rem #A5A5A5;
  padding: 1.2rem;
  font-size: 1.4rem;
  background-color: #ffffff;
  }

  .teacherSideBarContainer > .contents > .postSection > a > .posts:hover {
    background-color: #e9eef6;
    box-shadow: 0 15px 30px -5px rgba(0,0,0,.15), 0 0 5px rgba(0,0,0,.1);
	  transform: translateY(-4px);
  }
  
  .teacherSideBarContainer > .contents > .postSection > a > .posts > .name {
  color: #0D63BE;
  font-weight: bold;
  font-size: 1.6rem;
  text-decoration: underline;
  }

  .teacherSideBarContainer > .contents > .postSection > a > .posts > .possible_prefecture, .final_edu, .wage {
    color: #3a3f4a;
  }

  .short_profile {
    position:relative;
    background: #8DE055;
    margin-top: 1rem;
    padding:1.4rem;
    text-align:left;
    color:#333333;
    font-size:14px;
    border-radius:23px;
    -webkit-border-radius:23px;
    -moz-border-radius:23px;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  }
  
  .short_profile::before {
    border: solid transparent;
    content:'';
    pointer-events:none;
    position:absolute;
    border-color: rgba(90, 230, 40, 0);
    border-top-width: 1rem;
    border-bottom-width: 1.2rem;
    border-left-width:12px;
    border-right-width:12px;
    margin-left: -12px;
    border-bottom-color:#8DE055;
    bottom:100%;
    left:22%;
  }

/* RecruitePage */
.mainSection > .mainContents > .recruitInfo {
border: 1px solid #073898;
border-bottom: 0;
margin: 0 auto;
max-width: 100rem;
text-align: center;
}

.mainSection > .mainContents > .recruitInfo > .tableTitle {
border-bottom: 1px solid #073898;
background-color: #073898;
color: #ffffff;
font-size: 2rem;
margin: 0;
padding: 1rem;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem {
border-bottom: 1px solid #073898;
display: flex;
flex-wrap: wrap;
font-size: 1.6rem;
width: 100%;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dt {
color: #073898;
margin: auto;
padding: 1rem;
width: 30%;
font-weight: bold;
text-align: left;
}

.mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dd {
border-left: 1px solid #073898;
color: #3a3f4a;
padding: 1.2rem;
width: 70%;
text-align: left;
}

.recruitePageContainer > .mainSection > .leftContents {
width: 18%;
}

.recruitePageContainer > .mainSection > .mainContents {
width: 60%;
}

.recruitePageContainer > .mainSection > .rightContents {
width: 18%;
}

.recruitePageContainer > .mainSection > .mainContents > .introduction {
  margin-bottom: 3rem;
}

.recruitePageContainer > .mainSection > .mainContents > .introduction > h1 {
  border-left: 0.5rem solid #f46036;
  padding-left: 1rem;
  color: #073898;
  font-size: 2rem;
  margin-bottom: 1.6rem;
}

.recruitePageContainer > .mainSection > .mainContents > .introduction > p {
  font-size: 1.6rem;
}

.recruitePageContainer > .mainSection > .mainContents > .apply > h1 {
  margin: 4rem 0 1.6rem;
  background-color: #073898;
  padding-left: 1rem;
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
}

.recruitePageContainer > .mainSection > .mainContents > .apply > h2 {
  border-bottom: 1px solid #000000;
  font-size: 2rem;
  margin: 2.4rem 0 1.4rem;
}

.recruitePageContainer > .mainSection > .mainContents > .apply > p {
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
}

.recruitePageContainer > .mainSection > .mainContents > .apply > p > span {
  color: red;
  font-weight: bold;
}

.recruitePageContainer > .mainSection > .mainContents > .apply > p > .toLink {
  font-weight: bold;
}

/* TeacherPage */
.teacherPageContainer > .mainSection > .mainContents > .recruitInfo {
border: 1px solid #073898;
border-bottom: 0;
margin: 0 auto;
max-width: 100rem;
text-align: center;
}

.teacherPageContainer > .mainSection > .mainContents > .recruitInfo > .tableTitle {
border-bottom: 1px solid #073898;
background-color: #073898;
color: #ffffff;
font-size: 2rem;
margin: 0;
padding: 1rem;
}

.teacherPageContainer > .mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem {
border-bottom: 1px solid #073898;
display: flex;
flex-wrap: wrap;
font-size: 1.6rem;
width: 100%;
}

.teacherPageContainer > .mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dt {
color: #073898;
margin: auto;
padding: 1rem;
width: 30%;
font-weight: bold;
text-align: left;
}

.teacherPageContainer > .mainSection > .mainContents > .recruitInfo > .tableItems > .tableItem > dd {
border-left: 1px solid #073898;
color: #3a3f4a;
padding: 1.2rem;
width: 70%;
text-align: left;
}

.teacherPageContainer > .mainSection > .leftContents {
width: 18%;
}

.teacherPageContainer > .mainSection > .mainContents {
width: 60%;
}

.teacherPageContainer > .mainSection > .rightContents {
width: 18%;
}

.teacherPageContainer > .mainSection > .mainContents > .introduction {
  margin-bottom: 3rem;
}

.teacherPageContainer > .mainSection > .mainContents > .introduction > h1 {
  border-left: 0.5rem solid #f46036;
  padding-left: 1rem;
  color: #073898;
  font-size: 2rem;
  margin-bottom: 1.6rem;
}

.teacherPageContainer > .mainSection > .mainContents > .introduction > p {
  font-size: 1.6rem;
}

.teacherPageContainer > .mainSection > .mainContents > .introduction > p > span{
  font-size: 1.6rem;
  color: red;
  font-weight: bold;
}

.teacherPageContainer > .mainSection > .mainContents > .apply > h1 {
  margin: 4rem 0 1.6rem;
  background-color: #073898;
  padding-left: 1rem;
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
}

.teacherPageContainer > .mainSection > .mainContents > .apply > .heading > h2 {
  border-bottom: 1px solid #000000;
  font-size: 2rem;
  margin: 2.4rem 0 1.4rem;
}

.teacherPageContainer > .mainSection > .mainContents > .apply > .heading > p {
  font-size: 1.6rem;
}

.teacherPageContainer > .mainSection > .mainContents > .apply >  .heading > p > span {
  color: red;
  font-weight: bold;
}

.teacherPageContainer > .mainSection > .mainContents > .apply > p > .toLink {
  font-weight: bold;
}

.teacherPageContainer > .mainSection > .mainContents > .apply > .heading > .hubspotform {
  margin: 2rem 0 0;
}

}

/* /*
#=================================================================
 # Global selectors
 # ================================================================= */

 html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
  }
  
  *,
  ::before,
  ::after {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: inherit;
  }
  
  ::before,
  ::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
  }
  
  * {
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
  }
  
  /* # =================================================================
   # General elements
   # ================================================================= */
  
  hr {
  overflow: visible; /* Show the overflow in Edge and IE */
  height: 0; /* Add the correct box sizing in Firefox */
  color: inherit; /* Correct border color in Firefox. */
  }
  
  details,
  main {
  display: block; /* Render the `main` element consistently in IE. */
  }
  
  summary {
  display: list-item; /* Add the correct display in all browsers */
  }
  
  small {
  font-size: 80%; /* Set font-size to 80% in `small` elements */
  }
  
  [hidden] {
  display: none; /* Add the correct display in IE */
  }
  
  abbr[title] {
  border-bottom: none; /* Remove the bottom border in Chrome 57 */
  /* Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari */
  text-decoration: underline;
  text-decoration: underline dotted;
  }
  
  a {
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
  }
  
  a:active,
  a:hover {
  outline-width: 0; /* Remove the outline when hovering in all browsers */
  }
  
  code,
  kbd,
  pre,
  samp {
  font-family: monospace, monospace; /* Specify the font family of code elements */
  }
  
  pre {
  font-size: 1em; /* Correct the odd `em` font sizing in all browsers */
  }
  
  b,
  strong {
  font-weight: bolder; /* Add the correct font weight in Chrome, Edge, and Safari */
  }
  
  /* https://gist.github.com/unruthless/413930 */
  sub,
  sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  }
  
  sub {
  bottom: -0.25em;
  }
  
  sup {
  top: -0.5em;
  }
  
  table {
  border-color: inherit; /* Correct border color in all Chrome, Edge, and Safari. */
  text-indent: 0; /* Remove text indentation in Chrome, Edge, and Safari */
  }
  
  iframe {
  border-style: none;
  }
  
  /* # =================================================================
   # Forms
   # ================================================================= */
  
  input {
  border-radius: 0;
  }
  
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
  height: auto; /* Correct the cursor style of increment and decrement buttons in Chrome */
  }
  
  [type='search'] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
  }
  
  [type='search']::-webkit-search-decoration {
  -webkit-appearance: none; /* Remove the inner padding in Chrome and Safari on macOS */
  }
  
  textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
  font: inherit; /* Specify font inheritance of form elements */
  }
  
  optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule */
  }
  
  button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
  }
  
  button,
  select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
  }
  
  /* Apply cursor pointer to button elements */
  button,
  [type='button'],
  [type='reset'],
  [type='submit'],
  [role='button'] {
  cursor: pointer;
  }
  
  /* Remove inner padding and border in Firefox 4+ */
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
  }
  
  /* Replace focus style removed in the border reset above */
  button:-moz-focusring,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
  outline: 1px dotted ButtonText;
  }
  
  button,
  html [type='button'], /* Prevent a WebKit bug where (2) destroys native `audio` and `video`controls in Android 4 */
  [type='reset'],
  [type='submit'] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
  }
  
  /* Remove the default button styling in all browsers */
  button,
  input,
  select,
  textarea {
  background-color: transparent;
  border-style: none;
  }
  
  a:focus,
  button:focus,
  input:focus,
  select:focus,
  textarea:focus {
  outline-width: 0;
  }
  
  /* Style select like a standard input */
  select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
  }
  
  select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
  }
  
  select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
  }
  
  legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge 18- and IE */
  }
  
  ::-webkit-file-upload-button {
  /* Correct the inability to style clickable types in iOS and Safari */
  -webkit-appearance: button;
  color: inherit;
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
  }
  
  /* Replace pointer cursor in disabled elements */
  [disabled] {
  cursor: default;
  }
  
  /* # =================================================================
   # Specify media element style
   # ================================================================= */
  
  img {
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
  }
  
  /* Add the correct vertical alignment in Chrome, Firefox, and Opera */
  progress {
  vertical-align: baseline;
  }
  
  /* # =================================================================
   # Accessibility
   # ================================================================= */
  
  /* Specify the progress cursor of updating elements */
  [aria-busy='true'] {
  cursor: progress;
  }
  
  /* Specify the pointer cursor of trigger elements */
  [aria-controls] {
  cursor: pointer;
  }
  
  /* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
  [aria-disabled='true'] {
  cursor: default;
  }